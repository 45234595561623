import { isRouteErrorResponse, useRouteError } from "react-router-dom"
import RefreshUserDataButton from "./RefreshUserDataButton"

export function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  return <>
    <div>
      <h1>
        Strautmann SDS
      </h1>
    </div>
    <div>
      Es ist ein Fehler aufgetreten.
      Sie können versuchen, alle Daten neu zu laden, oder sich neu anzumelden.
    </div>
    <div>
      <RefreshUserDataButton />
    </div>
    <div>
      <a href="/login">Abmelden</a>
    </div>
    <div className='mt-20 text-s text-gray-300'>
      {isRouteErrorResponse(error) && (
        <>
          <div>{error.status}</div>
          <div>{error.data}</div>
          {error.data?.message && <div>{error.data.message}</div>}
        </>
      )}
      {
        // @ts-ignore
        error?.message && <div>{error.message}</div>
      }
    </div>
  </>
}
