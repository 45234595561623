import { useLoaderData, useNavigate } from "react-router-dom"
import { Flex, Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react"
import { FaChartSimple } from "react-icons/fa6"

import { CURRENCY_SYMBOL, UNIT_OF_MASS } from "../../../org-settings"

export default function Feedings() {
  const navigate = useNavigate()
  const feedingStats = useLoaderData() as any
  const icon = <FaChartSimple className="w-5 _h-3.5_ relativ text-[#3E9E29]" />
  const title = "Fütterungen"
  const _className = "feedings"

  let content
  if (!feedingStats
    || !feedingStats.meta) {
    content = (
      <div>Fehler: Noch keine Statistik-Daten vorhanden?</div>
    )
  } else {
    content = (
      <div className="rm-list-container">
        <div className="mb-2">
          Von <u>
            {new Date(feedingStats.meta.begin).toLocaleString('de-de', {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </u> bis <u>
            {new Date(feedingStats.meta.end).toLocaleString('de-de', {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </u>
        </div>
        <Table highlightOnHover={true} size="small" variation="striped">
          <TableHead>
            <TableRow className="bg-gray-300">
              <TableCell as="th">Start, Ende</TableCell>
              <TableCell as="th">Rezeptname</TableCell>
              <TableCell as="th">Fahrer</TableCell>
              <TableCell as="th">Geplant</TableCell>
              <TableCell as="th">Ladung</TableCell>
              <TableCell as="th">
                Fehler {/* (Ladung Soll vs Ladung Ist +/-) */}
              </TableCell>
              <TableCell as="th">Abladung</TableCell>
              <TableCell as="th">
                Fehler {/* (Ladung Soll vs Abladung Ist +/-) */}
              </TableCell>
              <TableCell as="th">Arb. Zeit</TableCell>
              {/* <TableCell as="th">Kosten</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody style={{ cursor: "pointer" }}>
            {feedingStats.SingleFeedings
              .sort((a: any, b: any) => { return a.Start < b.Start ? 1 : -1 })
              .map((feeding: any) => {
                const href = './' + feeding.FeedingReportId.replace("#", "||")
                return <TableRow onClick={() => navigate(href)}>
                  <TableCell>
                    {new Date(feeding.Start).toLocaleDateString('de-de', {
                      dateStyle: "short"
                    })}
                    {' '}
                    {new Date(feeding.Start).toLocaleTimeString('de-de', {
                      timeStyle: "short"
                    })}
                    {' - '}
                    {new Date(feeding.End).toLocaleTimeString('de-de', {
                      timeStyle: "short"
                    })}
                  </TableCell>
                  <TableCell>{feeding.Recipename}</TableCell>
                  <TableCell>{feeding.Driver}</TableCell>
                  <TableCell>
                    {feeding.LoadingTarget.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    {` ${UNIT_OF_MASS}`}
                  </TableCell>
                  <TableCell>
                    {feeding.Loaded.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    {` ${UNIT_OF_MASS}`}
                  </TableCell>
                  <TableCell className={Math.abs(feeding.LoadingRatio) > 3 ? "!bg-red-100 !_order-2 !_order-red-300" : ""}>
                    {feeding.LoadingRatio >= 0 && <span style={{ visibility: "hidden", opacity: 0 }}>-</span>}
                    {feeding.LoadingRatio.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} %
                  </TableCell>
                  <TableCell>
                    {feeding.Unloaded.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    {` ${UNIT_OF_MASS}`}
                  </TableCell>
                  <TableCell className={Math.abs(feeding.UnloadingRatio) > 3 ? "!bg-red-100 !_order-2 !_order-red-300" : ""}>
                    {feeding.UnloadingRatio >= 0 && <span style={{ visibility: "hidden", opacity: 0 }}>-</span>}
                    {feeding.UnloadingRatio.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} %
                  </TableCell>
                  <TableCell>{feeding.Duration} Min.</TableCell>
                  {/* <TableCell>n/a {CURRENCY_SYMBOL}</TableCell> */}
                </TableRow>
              })}
          </TableBody>
        </Table>
      </div>
    )
  }
  return <div className="rm-list-page">
    <Flex className="!h-12 !pb-2 !justify-between !items-center">
      <Flex className="!h-6 !justify-start !items-center gap-3">
        {icon}
        <div className="text-xl font-semibold">{title}</div>
      </Flex>
    </Flex>
    {content}
  </div>
}
