import { useLoaderData } from "react-router-dom"
import { Flex, Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react"
import { FaChartSimple } from "react-icons/fa6"

import { UNIT_OF_MASS } from "../../../org-settings"

export default function VerbrauchOverall() {
  const feedingStats = useLoaderData() as any
  const icon = <FaChartSimple className="w-5 _h-3.5_ relativ text-[#3E9E29]" />
  const title = "Verbrauch"
  const _className = "chart-container"

  let content
  if (!feedingStats
    || !feedingStats.meta) {
    content = (
      <div>Fehler: Noch keine Statistik-Daten vorhanden?</div>
    )
  } else {
    content = (
      <div className="rm-list-container">
        <div className="mb-2">
          Von <u>{new Date(feedingStats.meta.begin).toLocaleString('de-de')}</u> bis <u>{new Date(feedingStats.meta.end).toLocaleString('de-de')}</u>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell as="th">Futtermittel</TableCell>
              {/* <TableCell as="th">Frischmasse</TableCell> */}
              <TableCell as="th">{/* TS- */}Gewicht</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedingStats.total_cons_7days.map((e: any) => {
              // console.log("e = ", e)
              return <TableRow>
                <TableCell>{e.Komponentenname}</TableCell>
                {/* <TableCell>{e.Komponentenname}</TableCell> */}
                <TableCell>{e.Abladung.toLocaleString(undefined, { maximumFractionDigits: 0 })} {UNIT_OF_MASS}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
  return <div className="rm-list-page">
    <Flex className="!h-12 !pb-2 !justify-between !items-center">
      <Flex className="!h-6 !justify-start !items-center gap-3">
        {icon}
        <div className="text-xl font-semibold">{title}</div>
      </Flex>
    </Flex>
    {content}
  </div>
}
