import { useLoaderData } from "react-router-dom"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react"

import { FeedComponentDto } from "sds"
import { UNIT_OF_LARGE_MASS, UNIT_OF_MASS } from "../../../org-settings"
import SecondaryListItem from "./SecondaryListItem"

type VerbrauchPerFeedComponentProps = {
  _className?: string
  feedComponent: FeedComponentDto
}
export default function VerbrauchPerFeedComponent({ feedComponent, _className }: VerbrauchPerFeedComponentProps) {
  const feedingStats = useLoaderData() as any
  const getErrorUi = () => {
    return (
      <SecondaryListItem title="Verbrauch" _className={_className}>
        {feedComponent.name} wurde in keinem Rezept verwendet.
      </SecondaryListItem>
    )
  }
  if (!feedingStats
    || !feedingStats.ConsumptionPerComponent
  ) {
    return getErrorUi()
  }
  const data = (feedingStats.ConsumptionPerComponent as {
    [feedComponentId: string]: {
      "Komponenten-ID": string,
      Komponentenname: string,
      Abladung: number,
      Ladung: number,
    }
  })[feedComponent.id]

  if (!data) {
    return getErrorUi()
  }

  return (
    <SecondaryListItem title="Verbrauch" _className={_className}>
      <div className="mb-2">
        Von <u>{new Date(feedingStats.meta.begin).toLocaleString('de-de')}</u> bis <u>{new Date(feedingStats.meta.end).toLocaleString('de-de')}</u>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell as="th">Futtermittel</TableCell>
            <TableCell as="th">{/* TS- */}Gewicht</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{data.Komponentenname}</TableCell>
            <TableCell>
              {data.Ladung > 2000
                ? `${(data.Ladung / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 })} ${UNIT_OF_LARGE_MASS}`
                : `${data.Ladung.toLocaleString(undefined, { maximumFractionDigits: 0 })} ${UNIT_OF_MASS}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SecondaryListItem>
  )
}
