import { useContext, useEffect, useState } from 'react'
import { LoaderFunction, Navigate, NavigateFunction, Outlet, useLoaderData, useLocation, useNavigate, useRevalidator } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { signOut } from 'aws-amplify/auth'
import { Cache, I18n } from 'aws-amplify/utils'
import { Authenticator, Theme, ThemeProvider, translations, useAuthenticator } from '@aws-amplify/ui-react'

import { UserDataDto } from 'sds'

import { configureApis } from './api/utils'
import UserDataContextProvider, { IClearUserDataFunc, reloadUserData, UserDataContext, UserDataContextHelper } from './contexts/UserDataContextProvider'
import UserPreferencesContextProvider, { UserPreferencesContext } from "./contexts/UserPreferencesContextProvider"
import MainMenu from './components/MainMenu'
import ScrollToTop from './components/ScrollToTop'
import { CacheKeys } from './CacheKeys'
import { isDev, isProd } from './config'
import amplifyconfig from './amplifyconfiguration.json'

import './tw-init.css'
import '@aws-amplify/ui-react/styles.css'
// Supports weights 100-900
import '@fontsource-variable/inter'
import './global.css'
import './App.css'

Amplify.configure(amplifyconfig)

configureApis()

I18n.putVocabularies(translations)

const sdsTheme: Theme = {
  name: 'sds-theme',
  tokens: {
    // colors: {
    //   font: {
    //     primary: { value: '#008080' },
    //   },
    // },
    components: {
      button: {
        // default style: color, border dark
        _hover: {
          color: 'var(--bsl-green)',
          backgroundColor: 'var(--green-lighter-amp10)',
          borderColor: 'var(--bsl-green)',
        },
        _focus: {
          color: '{components.button._hover.color}',
          backgroundColor: '{components.button._hover.backgroundColor}',
          borderColor: '{components.button._hover.borderColor}',
        },
        _active: {
          color: '{components.button._hover.color}',
          backgroundColor: 'var(--green-lighter-amp20)',
          borderColor: '{components.button._hover.borderColor}',
        },
        primary: {
          color: 'white',
          backgroundColor: 'var(--bsl-green)',
          borderColor: 'var(--bsl-green)',
          _hover: {
            color: '{components.button.primary.color}',
            backgroundColor: 'var(--bsl-green-darker-10)',
            borderColor: '{components.button.primary._hover.backgroundColor}',
          },
          _focus: {
            color: '{components.button.primary._hover.color}',
            backgroundColor: '{components.button.primary._hover.backgroundColor}',
            borderColor: '{components.button.primary._focus.backgroundColor}',
          },
          _active: {
            backgroundColor: 'var(--bsl-green-darker-20)',
            borderColor: '{components.button.primary._active.backgroundColor}',
          },
        },
        link: {
          color: 'var(--bsl-green)',
          _hover: {
            color: '{components.button.link.color}',
            backgroundColor: '{components.button._hover.backgroundColor}',
          },
          _focus: {
            color: '{components.button.link.color}',
            backgroundColor: '{components.button._hover.backgroundColor}',
          },
          _active: {
            color: '{components.button.link.color}',
            backgroundColor: '{components.button._active.backgroundColor}',
            borderColor: '{components.button._active.backgroundColor}',
          },
        },
      },
      fieldcontrol: {
        _focus: {
          borderColor: 'var(--bsl-green)',
          boxShadow: '0px 0px 0px 2px var(--bsl-green)', // Dimensions are Amplify UI defaults
        }
      },
      tabs: {
        item: {
          _hover: {
            color: 'var(--bsl-green)',
          },
          _focus: {
            color: 'var(--bsl-green)',
          },
          _active: {
            color: 'var(--bsl-green)',
            borderColor: 'var(--bsl-green)',
            backgroundColor: 'var(--green-lighter-amp10)'
          }
        }
      }
    },
    fonts: {
      default: {
        static: '"Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif',
        variable: '"Inter Variable", "InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif',
      },
    },
    // fontSizes: {
    //   medium: "12px",
    //   large: "14px",
    //   xl: "16px",
    // },
  },
}

/**
 *  @note: `AppAuthSwitcher` must be its own component
 * (vs. inlined in the `App` function below) to be able to use the `useAuthenticator` hook.
 */
function AppAuthSwitcher() {
  const location = useLocation()
  const { authStatus } = useAuthenticator(context => [context.authStatus])

  if (authStatus === "configuring") {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (authStatus === "configuring") ...</>
  }

  if (location.pathname.startsWith('/login')) {
    // console.log("🔑 AppAuthSwitcher: Rendering public login route")
    return <Outlet />
  }

  if (authStatus !== 'authenticated') {
    // console.log(`🔑 AppAuthSwitcher: Redirect to login (authStatus === ${authStatus})`)
    return <Navigate
      to={`/login`}
      state={{ from: location }}
      replace />
  } else {
    // console.log("🔑 AppAuthSwitcher: Rendering private route")
    return (
      <ScrollToTop>
        <UserDataContextProvider>
          <UserPreferencesContextProvider>
            <AppAuthenticated />
          </UserPreferencesContextProvider>
        </UserDataContextProvider>
      </ScrollToTop>
    )
  }
}

const useSignOutHandler = (clearUserData: IClearUserDataFunc | undefined, navigate: NavigateFunction) => {
  const revalidator = useRevalidator()
  return async () => {
    console.log("> custom sign out handler")
    await signOut()
    console.log("  🔒 user is signed out")
    // Clear cache, because another user may log in on this browser.
    // This could be replaced by keeping the cache, and validating its content against the current user id.
    console.log("  🧹 clearing cache")
    await Cache.clear()
    if (clearUserData) {
      console.log("  🧹 clearing UserDataContext")
      clearUserData()
      console.log("  💫 trigger revalidate")
      revalidator.revalidate()
    }
    // localStorage can be kept.
    // It's unusual that another user signs in, and if so, they may be presented with the ErrorBoundary and Refresh would clear the localStorage.
    // In the future, it may make sense to namespace user settings with the userId.
    console.log("  redirect")
    navigate('/')
  }
}

function AppAuthenticated() {
  const env = process.env.NODE_ENV.toLowerCase()
  const navigate = useNavigate()
  const { authStatus } = useAuthenticator(context => [context.authStatus])
  const userDataFromLoader = useLoaderData() as UserDataDto
  // console.log("AppAuthenticated: loader user data =", userDataFromLoader)
  const { userPreferences, setUserPreference } = useContext(UserPreferencesContext)
  // console.log("AppAuthenticated: userPreferences =", userPreferences)
  const { userData: userDataCTX, setUserData, clearUserData } = useContext(UserDataContext)
  console.log("AppAuthenticated: userDataCTX =", userDataCTX)
  const signOutHandler = useSignOutHandler(clearUserData, navigate)

  const [activeOrgId, setActiveOrgId] = useState<string | undefined>(userPreferences?.[CacheKeys.UserPreferences.ActiveOrgId])
  // console.log("AppAuthenticated: activeOrgId =", activeOrgId)
  const [reloadRequired, setReloadRequired] = useState(false)

  if (authStatus === 'authenticated' && !userDataFromLoader) {
    if (!reloadRequired) {
      // console.log("AppAuthenticated.useEffect (do-navigate) -> really set should reload")
      setReloadRequired(true)
    }
  }

  useEffect(() => {
    // console.log("App.useEffect (user data -> context)")
    if (userDataFromLoader && !userDataCTX) {
      // console.log("Got user data, updating CONTEXT")
      if (!setUserData) {
        console.warn("❌ setUserData is not set.")
      } else {
        setUserData(userDataFromLoader)
      }
    }
  }, [userDataFromLoader, userDataCTX])

  useEffect(() => {
    // console.log("AppAuthenticated.useEffect (do-navigate): authStatus =", authStatus)
    if (reloadRequired) {
      // console.log("AppAuthenticated.useEffect (do-navigate) -> should reload")
      setReloadRequired(false)
      setTimeout(() => { navigate('.', { state: null, replace: true }) }, 100)
    }
  }, [authStatus, navigate, reloadRequired])

  useEffect(() => {
    // console.log("> AppAuthenticated.useEffect (getActiveOrgId)")
    if (!userPreferences) {
      // console.log("  AppAuthenticated.useEffect (getActiveOrgId): skip, userPref not loaded, yet")
      return
    }
    const _activeOrgId = userPreferences?.[CacheKeys.UserPreferences.ActiveOrgId]
    // console.log("_activeOrgId:", _activeOrgId)
    if (_activeOrgId) {
      console.log("✅ Active org found:", _activeOrgId)
      setActiveOrgId(_activeOrgId)
    } else {
      console.log("⚡ No active org found, selecting the first one.")
      if (!userDataCTX) {
        console.log("❌ No userData in ctx.")
      }
      else {
        const firstOrg = UserDataContextHelper.findOrgs(userDataCTX)[0]
        // console.log("firstOrg:", firstOrg.id)
        // console.log("calling setActiveOrgId")
        setActiveOrgId(firstOrg.id)
        // console.log("calling setUserPreference")
        if (!setUserPreference) {
          console.log("❌ setUserPreference is not set.")
        } else {
          setUserPreference(CacheKeys.UserPreferences.ActiveOrgId, firstOrg.id)
        }
      }
    }
  }, [userPreferences, userDataCTX])

  if (!userDataCTX) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (userDataCTX) ...</>
  }
  return <>
    <header className="app-header">
      <h1 className='flex gap-x-6 items-center'>
        <img src="/logo-strautmann.svg" alt="Strautmann" />
        {isProd
          ? ''
          : <div>{`SDS /${isDev ? 'dev' : env}`}</div>
        }
      </h1>

      <div className='user-account flex justify-end items-center gap-x-3'>
        {/* TODO: avatar image */}
        {/* <div>(AV)</div> */}
        <div className='flex items-center gap-x-1'>
          {/* {user?.username} */}
          {userDataCTX.first_name} {userDataCTX.last_name}
          <svg className='inline' width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_140_164" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="8">
              <path d="M13 7.6547V0.345703L0.819 0.345703V7.6547L13 7.6547Z" fill="white" />
            </mask>
            <g mask="url(#mask0_140_164)">
              <path d="M2.03662 1.56348L6.90862 6.43548L11.7816 1.56348" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>
        </div>
        <div><button onClick={signOutHandler}>Abmelden</button></div>
      </div>
    </header>
    <MainMenu userData={userDataCTX} activeOrgId={activeOrgId} />
    <main className='main-container'>
      <Outlet />
    </main>
  </>
}

export default function App() {
  return <ThemeProvider theme={sdsTheme}>
    <Authenticator.Provider>
      <AppAuthSwitcher />
    </Authenticator.Provider>
  </ThemeProvider>
}

export const loader: LoaderFunction = async (args): Promise<UserDataDto | null> => {
  console.log("> App.loader")
  const userData = await reloadUserData()
  if (!userData) {
    return null
  }
  return userData
}

export const Component = App
