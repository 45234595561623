import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { Button, Flex, Grid, Sizes, View } from "@aws-amplify/ui-react"
import { FaPencil, FaPenToSquare, FaRegEye, FaRegStar, FaStar } from "react-icons/fa6"

export const imageHash = (hashSeed: number, uuid: string, N: number = 0): number => {
  let x = hashSeed
  uuid.split("-").forEach(segment => x ^= Number.parseInt(segment, 16))
  x = Math.abs(x)
  return N
    ? x % N
    : x
}

export enum StarStates {
  hidden = "hidden",
  empty = "empty",
  filled = "filled"
}
interface ListItemProps {
  className?: string
  title: string | React.ReactNode
  href?: string
  imageSrc: string
  details: string | React.ReactNode
  size?: Sizes
  starState?: StarStates
  hasViewButton?: boolean
  hasEditButton?: boolean
  viewButtonIcon?: React.ReactNode
}
export const ListItem = ({ href, title, imageSrc, className, details, size = "large", starState = StarStates.hidden, hasViewButton = true, hasEditButton = false, viewButtonIcon = <FaRegEye /> }: ListItemProps) => {
  const navigate = useNavigate()
  const buttonFlexClasses = size === "large"
    ? "!flex-col"
    : ""
  const firstColumnWidth = size === "large"
    ? "108px"
    : "56px"
  return (
    <Grid className={`list-item-grid ${className} ${size.toString()}`}
      templateColumns={`${firstColumnWidth} 0.5rem 1.5fr 2.5rem`} templateRows="1rlh auto"
      onClick={href ? () => navigate(href, { replace: false }) : undefined}>
      <View column={1} rowStart={1} rowEnd={-1} className="img flex-shrink-0" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`,
        cursor: "pointer",
      }} />

      <View column={3} row={1} className="info mb-2">
        <div className="title" style={{ cursor: "pointer" }}>
          {title}{
            (starState === StarStates.hidden)
              ? ''
              : <>&nbsp;{(starState === StarStates.empty)
                ? <FaRegStar className="fav" onClick={(e) => {
                  e.stopPropagation()
                  // alert('starClick')
                }
                } />
                : <FaStar className="fav is-fav" onClick={(e) => {
                  e.stopPropagation()
                  // alert('starClick')
                }
                } />}
              </>
          }
        </div>
      </View>

      <View columnStart={3} row={2} columnEnd={-1} className="details">
        {details}
      </View>

      <View column={-1} rowStart={1} rowEnd={-1}>
        {(hasViewButton || hasEditButton) &&
          <Flex className={buttonFlexClasses}>
            {(hasViewButton && href) && (
              <Link to={href}>
                <Button className="button-view has-icon"><span role="img" aria-label="Ansehen">{viewButtonIcon}</span></Button>
              </Link>
            )}
            {/* todo: css class button-view is incorrect ; button-edit has wrong colors */}
            {(hasEditButton && href) && (
              <Link to={`${href}/edit`}>
                <Button className="button-view has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
              </Link>
            )}
          </Flex>
        }
      </View>
    </Grid>
  )
}
