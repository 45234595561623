import { getCurrentUser } from "aws-amplify/auth"
import { Cache } from 'aws-amplify/utils'

import { AnimalGroupDto, FeedComponentDto, Organization, RecipeDto, UserDataDto } from "sds"
import { isProd } from "../config"

import { fetchFromApi, getCacheKey } from "./utils"

// 10 minutes
const EXPIRES_IN_MS = 10 * 60 * 1000

export const getCurrentUserData = async (): Promise<UserDataDto | null> => {
  console.log("> getCurrentUserData")
  const apiName = 'frontendApi'
  const path = '/users/current'
  const cacheKey = getCacheKey(apiName, path)

  // Only load if the user is signed in.
  let currentUser = undefined
  try {
    currentUser = await getCurrentUser()
    console.log("  getCurrentUserData: got signed in user:", currentUser)
  } catch (err) {
    // console.log("  cannot load user data: No signed in user.")
    if (!isProd) {
      console.log("  DEV: clearing cache")
      Cache.clear()
    }
    console.log("  no signed in user:", err)
    console.log("❌ getCurrentUserData: RETURN NULL")
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data = await Cache.getItem(cacheKey)
    if (!data
      || !data.id
      || data.id != currentUser.userId) {
      console.warn('  userId mismatch -> remove item from cache')
      if (!isProd) {
        console.warn(`  getCurrentUserData: current user = ${currentUser.userId} / cached = ${data.id}`)
      }
      await Cache.removeItem(cacheKey)
      data = undefined
    }
    if (data) {
      console.log('  getCurrentUserData: Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'getCurrentUserData: not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path)
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('  getCurrentUserData: Fetched from api:', data)
    }
    // console.log("✅ getCurrentUserData: data")
    return await data
  }
  catch (err) {
    console.error('Error fetching user data from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getCurrentUserData: throw")
    throw err
  }
}

/**
 * @deprecated Use UserDataContextHelper instead.
 */
export class UserDataHelper {

  static findOrgs(userData: UserDataDto): Organization[] {
    const orgs = userData.Organizations
    return orgs
  }

  static findOrg(orgId: string, userData: UserDataDto): Organization {
    try {
      const orgs = userData.Organizations
      const orgsFiltered = orgs.filter(o => o.id === orgId)
      if (!orgsFiltered) {
        throw new Error("findOrg: orgsFiltered is not set")
      }
      if (1 !== orgsFiltered.length) {
        throw new Error("findOrg: orgsFiltered is empty")
      }
      return orgsFiltered[0]
    } catch (err) {
      // console.log("findOrg / err:", err)
      // console.log("findOrg / err:", userData)
      throw err
    }
  }

  static findAnimalGroups(org: Organization): AnimalGroupDto[] {
    return org.Ausladegruppen
  }

  static findAnimalGroup(org: Organization, agId: string): AnimalGroupDto {
    const agsFiltered = org.Ausladegruppen.filter(ag => ag.id === agId)
    if (!agsFiltered) {
      throw new Error("findAnimalGroup: agsFiltered is not set")
    }
    if (1 !== agsFiltered.length) {
      throw new Error("findAnimalGroup: agsFiltered is empty")
    }
    return agsFiltered[0]
  }

  static findFeedComponents(org: Organization): FeedComponentDto[] {
    return org.FeedComponents
  }

  static findFeedComponent(org: Organization, feedComponentId: string): FeedComponentDto {
    const fcsFiltered = org.FeedComponents.filter(fc => fc.id === feedComponentId)
    if (!fcsFiltered) {
      throw new Error("findFeedComponent: fcsFiltered is not set")
    }
    if (1 !== fcsFiltered.length) {
      throw new Error("findFeedComponent: fcsFiltered is empty")
    }
    return fcsFiltered[0]
  }

  static findRecipes(org: Organization): RecipeDto[] {
    return org.Recipes
  }

  static findRecipe(org: Organization, recipeId: string): RecipeDto {
    const recipes = this.findRecipes(org)
    const recipesFiltered = recipes.filter(r => r.id === recipeId)
    if (!recipesFiltered) {
      throw new Error("findRecipe: recipesFiltered is not set")
    }
    if (1 !== recipesFiltered.length) {
      throw new Error("findRecipe: recipesFiltered is empty")
    }
    return recipesFiltered[0]
  }
}
