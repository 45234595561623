import { createContext, PropsWithChildren, useState } from "react"

import { AnimalGroupDto, FeedComponentDto, Organization, RecipeDto, UserDataDto } from "sds"
import { getCurrentUserData } from "../api/userData"

export type IClearUserDataFunc = () => void
type IReloadUserDataFunc = () => Promise<UserDataDto | undefined>

export const reloadUserData: IReloadUserDataFunc = async (): Promise<UserDataDto | undefined> => {
  console.log("> UserDataContextProvider reloadUserData")
  const userData = await getCurrentUserData()
  if (!userData) {
    console.log("  😕 got no user data")
    return undefined
  }
  // console.log("  🎉 got user data")
  console.log("  🧪 Patching address data")
  if (userData.Organizations) {
    userData.Organizations.forEach(org => {
      if (org.name === "Andreas" || org.name === "Haggeney") {
        org.address = {
          street: "Bielefelder Straße",
          number: "53",
          zip: "49196",
          city: "Bad Laer",
          country: "de",
          gps: {
            lat: 52.102019,
            lng: 8.101101
          }
        }
      }
      if (org.name === "Heiner" || org.name === "Große Börding") {
        org.address = {
          street: "Heidestraße",
          number: "9",
          zip: "49219",
          city: "Glandorf",
          country: "de",
          gps: {
            lat: 52.097583,
            lng: 7.998280
          }
        }
      }
      if (org.name === "AG Eibau" || org.name === "Eibau") {
        org.address = {
          street: "Ruppersdorfer Str.",
          number: "9",
          zip: "02739",
          city: "Kottmar",
          country: "de",
          gps: {
            lat: 50.988894,
            lng: 14.667677
          }
        }
      }
    })
  }
  console.log("< CTX reloadUserData")
  return userData
}

interface IUserDataContext {
  userData?: UserDataDto
  setUserData?: React.Dispatch<React.SetStateAction<UserDataDto | undefined>>
  clearUserData?: IClearUserDataFunc
  reloadUserDataAsync: IReloadUserDataFunc
}
export const UserDataContext = createContext<IUserDataContext>({
  userData: undefined,
  setUserData: undefined,
  clearUserData: undefined,
  reloadUserDataAsync: reloadUserData
})

export default function UserDataContextProvider({ children }: PropsWithChildren) {
  const [userData, setUserData] = useState<UserDataDto | undefined>(undefined)

  const clearUserData: IClearUserDataFunc = () => {
    console.log("> UserDataContextProvider.clearUserData")
    setUserData(undefined)
  }
  const reloadAndSetUserData: IReloadUserDataFunc = async () => {
    const updatedUserData = await reloadUserData()
    setUserData(updatedUserData)
    return updatedUserData
  }
  return (
    <UserDataContext.Provider value={{
      userData,
      setUserData,
      clearUserData,
      reloadUserDataAsync: reloadAndSetUserData
    }}>
      {children}
    </UserDataContext.Provider>
  )
}

export class UserDataContextHelper {

  static findOrgs(userData: UserDataDto): Organization[] {
    return userData.Organizations
  }

  static findOrg(orgId: string, userData: UserDataDto): Organization {
    const orgs = userData.Organizations
    const orgsFiltered = orgs.filter(o => o.id === orgId)
    if (!orgsFiltered) {
      throw new Error("findOrg: orgsFiltered is not set")
    }
    if (1 !== orgsFiltered.length) {
      throw new Error("findOrg: orgsFiltered is empty")
    }
    return orgsFiltered[0]
  }


  static findAnimalGroups(org: Organization): AnimalGroupDto[] {
    return org.Ausladegruppen
  }

  static findAnimalGroup(org: Organization, agId: string): AnimalGroupDto {
    const agsFiltered = org.Ausladegruppen.filter(ag => ag.id === agId)
    if (!agsFiltered) {
      throw new Error("findAnimalGroup: agsFiltered is not set")
    }
    if (1 !== agsFiltered.length) {
      throw new Error("findAnimalGroup: agsFiltered is empty")
    }
    return agsFiltered[0]
  }

  static findFeedComponents(org: Organization): FeedComponentDto[] {
    return org.FeedComponents
  }

  static findFeedComponent(org: Organization, feedComponentId: string): FeedComponentDto {
    const fcsFiltered = org.FeedComponents.filter(fc => fc.id === feedComponentId)
    if (!fcsFiltered) {
      throw new Error("findFeedComponent: fcsFiltered is not set")
    }
    if (1 !== fcsFiltered.length) {
      throw new Error("findFeedComponent: fcsFiltered is empty")
    }
    return fcsFiltered[0]
  }

  static findRecipes(org: Organization): RecipeDto[] {
    return org.Recipes
  }

  static findRecipe(org: Organization, recipeId: string): RecipeDto {
    const recipes = this.findRecipes(org)
    const recipesFiltered = recipes.filter(r => r.id === recipeId)
    if (!recipesFiltered) {
      throw new Error("findRecipe: recipesFiltered is not set")
    }
    if (1 !== recipesFiltered.length) {
      throw new Error("findRecipe: recipesFiltered is empty")
    }
    return recipesFiltered[0]
  }

}
