import { useContext, useState } from "react"
import { Link, useParams, useRouteLoaderData } from "react-router-dom"
import { Button, ButtonGroup, Fieldset, Flex, Input, Label, Radio, RadioGroupField, SelectField, StepperField, SwitchField } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaXmark } from "react-icons/fa6"

import { Organization, UserDataDto } from "sds"

import config, { isProd } from "../../../config"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"
import { getLinkToOrg } from "../../../LinkUtils"

import { imageHash } from "../-components/ListItem"

import '../RecipeManagement.css'

const NUM_ORG_IMAGES = config.images.orgs.numImages
const IMAGE_HASH_SEED = config.images.orgs.imageHashSeed

export default function StatsSettings() {
  const params = useParams()
  const { userData: rootData } = useContext(UserDataContext)
  // const rootData = useRouteLoaderData("root") as UserDataDto
  // console.log("🧪 OrgDashboard / rootData:", rootData)
  const [org, setOrg] = useState<Organization | undefined>(undefined)

  if (!rootData) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (rootData) ...</>
  }

  let org_: Organization | undefined = undefined
  if (rootData) {
    org_ = UserDataContextHelper.findOrg(params.orgId!, rootData)
  }
  if (!org) {
    setOrg(org_)
  }
  if (!org_ || !org) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (org) ...</>
  }

  const imageIdx = imageHash(IMAGE_HASH_SEED, org.id, NUM_ORG_IMAGES)
  const imageSrc = org.name === "Andreas" || org.name === "Haggeney"
    ? `/images/orgs/hof-pixabay-1.jpg`
    : org.name === "Heiner" || org.name === "Große Börding"
      ? `/images/orgs/hof-pixabay-2.jpg`
      : `/images/orgs/hof-pixabay-${imageIdx}.jpg`
  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{org.name} &ndash; Einstellungen für Statistiken</h2>
          </div>
        </div>

        <div className="details">
          <form>
            {/** @todo: {...register("fieldname")} */}
            <Fieldset legend="Diagramme" variation="outlined" size="small">
              <Flex direction="row">
                <StepperField label={<>
                  Korridor für "Okay":
                  {' '}
                  <span style={{ backgroundColor: "#25C401" }}>100 % +/-</span>
                </>} defaultValue={10} size="small" />
                <StepperField label={<>
                  Korridor für "Meh":
                  {' '}
                  <span style={{ backgroundColor: "#C4C401" }}>100 % +/-</span>
                </>} defaultValue={15} size="small" />
              </Flex>
            </Fieldset>

            <Fieldset legend="Berichte senden" variation="outlined" size="small" className="!mt-4">
              <SwitchField size="small" label="Berichte per E-Mail schicken" defaultChecked={true} />
            </Fieldset>

            <ButtonGroup className="buttons !mt-4" size="small">
              <Button size="small" variation="primary" type="submit" isDisabled={true}>
                Speichern
              </Button>
              <Link to="..">
                <Button size="small">Abbrechen</Button>
              </Link>
            </ButtonGroup>
          </form>
        </div>
      </div>
    </div>

    {/* <small>* Die Icons "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
