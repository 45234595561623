import { useLoaderData } from "react-router-dom"
import { ChartOptions } from "chart.js"
import 'chartjs-adapter-luxon'

import { AnimalGroupDto } from "sds"
import { getAnnotationSettings, prepareAccuracyDatasets } from "./ChartUtils"
import GenauigkeitSingleChart from "./GenauigkeitSingleChart"
import SecondaryListItem from "./SecondaryListItem"

type GenauigkeitPerAnimalGroupProps = {
  _className?: string
  animalGroup: AnimalGroupDto
}
export default function GenauigkeitPerAnimalGroup({ animalGroup, _className }: GenauigkeitPerAnimalGroupProps) {
  const feedingStats = useLoaderData() as any
  const getErrorUi = () => {
    return (
      <SecondaryListItem title="Genauigkeit" _className={_className}>
        {animalGroup.name} wurde in keinem Rezept verwendet.
      </SecondaryListItem>
    )
  }
  if (!feedingStats
    || !feedingStats.AccuracyPerAnimalGroup
  ) {
    return getErrorUi()
  }
  const data = (feedingStats.AccuracyPerAnimalGroup as {
    [animalGroupId: string]: {
      animalGroupId: string,
      label: string,
      xData: string[],
      yData: number[],
    }
  })[animalGroup.id]

  if (!data) {
    return getErrorUi()
  }

  const datasetAnimalGroup = prepareAccuracyDatasets({ [animalGroup.id]: data })

  const annotationSettings = getAnnotationSettings()
  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      annotation: {
        annotations: {
          boxYellowUpper: {
            type: 'box',
            yMin: annotationSettings.yellowUpper.min,
            yMax: annotationSettings.yellowUpper.max,
            backgroundColor: annotationSettings.yellowUpper.color,
            borderWidth: 0,
          },
          boxGreen: {
            type: 'box',
            yMin: annotationSettings.green.min,
            yMax: annotationSettings.green.max,
            backgroundColor: annotationSettings.green.color,
            borderWidth: 0,
          },
          boxYellowLower: {
            type: 'box',
            yMin: annotationSettings.yellowLower.min,
            yMax: annotationSettings.yellowLower.max,
            backgroundColor: annotationSettings.yellowLower.color,
            borderWidth: 0,
          },
        }
      }
    },
    scales: {
      x: {
        bounds: 'data' as const,
        type: 'time' as const,
        ticks: {
          source: 'auto' as const,
          /** @note without a callback, the chart collapses every now and then */
          callback: (value: any) => {
            const dt = new Date(value)
            return ('0' + dt.getDate()).slice(-2)
              + '.'
              + ('0' + (dt.getMonth() + 1)).slice(-2)
              + '. '
            // + ('0' + dt.getHours()).slice(-2)
            // + ':'
            // + ('0' + dt.getMinutes()).slice(-2)
          }
        },
      }
    }
  }

  return (
    <SecondaryListItem title="Genauigkeit" _className={`chart-container ${_className}`}>
      <GenauigkeitSingleChart _className="!w-full" series={datasetAnimalGroup} chartOptions={options} />
    </SecondaryListItem>
  )
}
