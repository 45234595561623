import { useLoaderData } from "react-router-dom"
import { Flex } from "@aws-amplify/ui-react"
import { FaChartSimple } from "react-icons/fa6"
import { ChartData, ChartDataset, ChartOptions } from "chart.js"
import 'chartjs-adapter-luxon'

import { getAnnotationSettings, getPersistentColor, PointInTime, prepareAccuracyDatasets } from "../-components/ChartUtils"
import GenauigkeitMultiChart from "../-components/GenauigkeitMultiChart"
import GenauigkeitSingleChart from "../-components/GenauigkeitSingleChart"

function prepareDatasets(chartData: {
  title?: string,
  xData: string[][],
  yData: number[][],
  labels: string[],
  xlabel?: string,
  ylabel?: string
}) {
  const labels = chartData.labels
  const xData = chartData.xData
  const yData = chartData.yData
  const numSeries = xData.length
  const mergedData: PointInTime[][] = []
  for (var s = 0; s < numSeries; s++) {
    const seriesX = xData[s]
    const seriesY = yData[s]
    const seriesMergedXY: PointInTime[] = []
    for (var i = 0; i < seriesX.length; i++) {
      seriesMergedXY.push({
        x: seriesX[i],
        y: seriesY[i]
      })
    }
    mergedData.push(seriesMergedXY)
  }

  const datasets: ChartData<'line', PointInTime[]> = {
    datasets: mergedData.map((series, index) => ({
      label: labels[index],
      data: series,
      borderColor: getPersistentColor(index),
      tension: 0.05,
      pointBackgroundColor: getPersistentColor(index),
    })) as ChartDataset<'line', PointInTime[]>[]
  }

  return datasets
}

export default function GenauigkeitOverall() {
  const feedingStats = useLoaderData() as any
  const icon = <FaChartSimple className="w-5 _h-3.5_ relativ text-[#3E9E29]" />
  const title = "Statistiken"
  const _className = "chart-container"

  let content
  if (!feedingStats
    || !feedingStats.AccuracyPerComponent
    || !feedingStats.AccuracyPerDriver) {
    content = (
      <div>Fehler: Noch keine Statistik-Daten vorhanden?</div>
    )
  } else {
    const datasetsComponents = prepareAccuracyDatasets(feedingStats.AccuracyPerComponent)
    const datasetsDrivers = prepareDatasets(feedingStats.AccuracyPerDriver)

    const annotationSettings = getAnnotationSettings()
    const options: ChartOptions<'line'> = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: false,
        },
        annotation: {
          annotations: {
            boxYellowUpper: {
              type: 'box',
              yMin: annotationSettings.yellowUpper.min,
              yMax: annotationSettings.yellowUpper.max,
              backgroundColor: annotationSettings.yellowUpper.color,
              borderWidth: 0,
            },
            boxGreen: {
              type: 'box',
              yMin: annotationSettings.green.min,
              yMax: annotationSettings.green.max,
              backgroundColor: annotationSettings.green.color,
              borderWidth: 0,
            },
            boxYellowLower: {
              type: 'box',
              yMin: annotationSettings.yellowLower.min,
              yMax: annotationSettings.yellowLower.max,
              backgroundColor: annotationSettings.yellowLower.color,
              borderWidth: 0,
            },
          }
        }
      },
      scales: {
        x: {
          bounds: 'data' as const,
          type: 'time' as const,
          ticks: {
            source: 'auto' as const,
            /** @note without a callback, the chart collapses every now and then */
            callback: (value: any) => {
              const dt = new Date(value)
              return ('0' + dt.getDate()).slice(-2)
                + '.'
                + ('0' + (dt.getMonth() + 1)).slice(-2)
                + '. '
              // + ('0' + dt.getHours()).slice(-2)
              // + ':'
              // + ('0' + dt.getMinutes()).slice(-2)
            }
          },
        }
      }
    }

    content = <div className="rm-list-container">
      <div className="mb-2">
        Von <u>{new Date(feedingStats.meta.begin).toLocaleString('de-de')}</u> bis <u>{new Date(feedingStats.meta.end).toLocaleString('de-de')}</u>
      </div>
      {/* <GenauigkeitMultiChart
    _className={_className}
    title={"MULTI +++ Durchschnittliche Genauigkeit der Futter-Komponenten"}
    multiSeries={[
      {
        chart: {
          name: "Genauigkeit",
          unit: "%"
        },
        data: datasetsComponents
      },
      {
        chart: {
          name: "Dauer",
          unit: "Min."
        },
        data: datasetsDrivers
      }
    ]}
    chartOptions={options} /> */}
      <GenauigkeitSingleChart _className={_className} title="Durchschnittliche Genauigkeit der Futter-Komponenten" series={datasetsComponents} chartOptions={options} />
      <GenauigkeitSingleChart _className={_className} title="Genauigkeit pro Fahrer (schlechteste Komponente)" series={datasetsDrivers} chartOptions={options} />
    </div>
  }
  return <div className="rm-list-page">
    <Flex className="!h-12 !pb-2 !justify-between !items-center">
      <Flex className="!h-6 !justify-start !items-center gap-3">
        {icon}
        <div className="text-xl font-semibold">{title}</div>
      </Flex>
    </Flex>
    {content}
  </div>
}
